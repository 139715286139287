import React from "react"
import cmsPromo from "../../../site/settings/promo.json"
import cmsStyle from "../../../site/settings/style.json"

const btnStyle = {
  backgroundColor: cmsStyle.brand_color,
  borderColor: cmsStyle.brand_color,
}

function ScheduleBtn(props) {
  return (
    <>
      <a
        style={btnStyle}
        className="btn btn-primary"
        id="schedule-tracking"
        href={cmsPromo.schedule_link}
      >
        {props.children}
      </a>
    </>
  )
}

export default ScheduleBtn
