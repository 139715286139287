import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/LayoutComponent"
import { Container, Row, Col, ListGroup, Card, CardDeck } from "react-bootstrap"
import Button from "../components/CustomBtn"
import ScheduleButton from "../components/btns/ScheduleBtn"
import BackgroundImage from "../components/BackgroundImg"
import Image from "../components/CloudinaryImage"
import netlifyStyle from "../../site/settings/style.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import SEO from "../components/seo"

const imgStyle = { width: "50%", height: "100%" }

const rootContainerStyle = {
  backgroundColor: "rgba(78, 78, 78, 0.651)",
  minWidth: "100vw",
  minHeight: "50vh"
}

// ! Change residential services to a accordion component

// ! NV ENERGY LINK IS STATIC

function Residential() {
  const data = useStaticQuery(graphql`
    query {
      residentialPage: allFile(filter: { base: { eq: "residential.md" } }) {
        edges {
          node {
            absolutePath
            relativeDirectory
            childMarkdownRemark {
              frontmatter {
                background
                heading
                main_prompt
                main_prompt_link
                main_subheading
                subheading_2
                paragraph_1
                img_1
                subheading_3
                service_1
                service_2
                service_3
                service_4
                service_5
                subheading_4
                logo_1
                logo_2
                logo_3
                featured_promo_heading
                featured_promo_subheading
                featured_promo_description
                featured_promo_image
                featured_promo_prompt
                featured_promo_prompt_link
                tips_heading
                tip_1_img
                tip_1_title
                tip_1_description
                tip_1_link_prompt
                tip_1_link
                tip_2_img
                tip_2_title
                tip_2_description
                tip_2_link_prompt
                tip_2_link
                tip_3_img
                tip_3_title
                tip_3_description
                tip_3_link_prompt
                tip_3_link
              }
            }
          }
        }
      }
    }
  `)

  const residentialContent =
    data.residentialPage.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <Layout>
      <SEO />
      <BackgroundImage markdownLink={residentialContent.background}>
        <Container
          style={rootContainerStyle}
          className=" mb-3 d-flex align-items-center"
        >
          <div
            className="w-100 h-1000 d-flex flex-column align-items-center justify-content-center p-3"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <h1
              className="mb-4 text-uppercase text-center"
              style={{ color: netlifyStyle.secondary_font_color }}
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              {residentialContent.heading}
            </h1>

            <a href={residentialContent.main_prompt_link} className="mb-3">
              <ScheduleButton>{residentialContent.main_prompt}</ScheduleButton>
            </a>
          </div>
        </Container>
      </BackgroundImage>
      <Container className="d-flex flex-column align-items-center justify-content-center">
        <h2
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="mb-lg-5 mb-xs-4"
        >
          {residentialContent.main_subheading}
        </h2>
        <Row
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="mb-3"
        >
          <Col xs={12} lg={6}>
            <h3>{residentialContent.subheading_2}</h3>
            <p>{residentialContent.paragraph_1}</p>
          </Col>
          <Col xs={12} lg={6}>
            <BackgroundImage markdownLink={residentialContent.img_1}>
              <div style={{ minHeight: "11rem" }}></div>
            </BackgroundImage>
          </Col>
        </Row>

        <Row className="mb-4" style={{ minWidth: "100%" }}>
          <Col
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            className="mb-3"
            xs={12}
            lg={6}
          >
            <h5 className="text-center">{residentialContent.subheading_3}</h5>
            <ListGroup style={{ minWidth: "100%" }}>
              <ListGroup.Item>{residentialContent.service_1}</ListGroup.Item>
              <ListGroup.Item>{residentialContent.service_2}</ListGroup.Item>
              <ListGroup.Item>{residentialContent.service_3}</ListGroup.Item>
              <ListGroup.Item>{residentialContent.service_4}</ListGroup.Item>
              <ListGroup.Item>{residentialContent.service_5}</ListGroup.Item>
            </ListGroup>
          </Col>

          <Col
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            xs={12}
            lg={6}
          >
            <h5 className="text-center">{residentialContent.subheading_4}</h5>
            <Row className="d-flex flex-column logo-section ">
              <Col className="mb-2 d-flex justify-content-center ">
                <Image
                  className={"mx-auto"}
                  markdownLink={residentialContent.logo_1}
                />
              </Col>
              {/*<Col className="mb-2 d-flex justify-content-center">*/}
              {/*  <Image*/}
              {/*    className={"mx-auto"}*/}
              {/*    markdownLink={residentialContent.logo_2}*/}
              {/*  />*/}
              {/*</Col>*/}
              <Col className="mb-2 d-flex justify-content-center">
                <Image
                  imgStyle={{ height: "auto", width: "39%" }}
                  className={"mx-auto"}
                  markdownLink={residentialContent.logo_3}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="border-dark w-100" />
        <h3
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="mb-4"
        >
          {residentialContent.featured_promo_heading}
        </h3>
        <Row>
          <Col
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            xs={12}
            lg={6}
            className="mb-3"
          >
            {" "}
            <h5 className="mb-1">
              {residentialContent.featured_promo_sub_heading}
            </h5>
            <p>{residentialContent.featured_promo_description}</p>
            <a
              href="https://www.nvenergy.com/publish/content/dam/nvenergy/brochures_arch/save-with-powershift/ac-discounts/NVE-Air-Conditioning-Minimum-Incentives.pdf"
              className="font-weight-bold text-uppercase"
              style={{ color: netlifyStyle.brand_color, padding: "1rem" }}
            >
              view discounts
            </a>
            <a
              className="font-weight-bold text-uppercase"
              style={{ color: netlifyStyle.brand_color }}
              href={residentialContent.featured_promo_prompt_link}
            >
              {residentialContent.featured_promo_prompt}{" "}
              <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
            </a>
          </Col>
          <Col
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            xs={12}
            lg={6}
          >
            {" "}
            <BackgroundImage
              markdownLink={residentialContent.featured_promo_image}
            >
              <div style={{ minHeight: "10rem" }}></div>
            </BackgroundImage>
          </Col>
        </Row>
        <hr className="border-dark w-100" />
        <h3
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="mb-4 text-uppercase"
        >
          {residentialContent.tips_heading}
        </h3>

        <div className="w-100 h-100">
          <CardDeck>
            <Card>
              <BackgroundImage
                htmlTag={"div"}
                markdownLink={residentialContent.tip_1_img}
              >
                <div style={{ minHeight: "11rem" }}></div>
              </BackgroundImage>

              <Card.Body>
                <Card.Title className="text-uppercase">
                  {residentialContent.tip_1_title}
                </Card.Title>
                <Card.Text>{residentialContent.tip_1_description}</Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <BackgroundImage
                htmlTag={"div"}
                markdownLink={residentialContent.tip_2_img}
              >
                <div style={{ minHeight: "11rem" }}></div>
              </BackgroundImage>

              <Card.Body>
                <Card.Title className="text-uppercase">
                  {residentialContent.tip_2_title}
                </Card.Title>
                <Card.Text>{residentialContent.tip_2_description}</Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <BackgroundImage
                htmlTag={"div"}
                markdownLink={residentialContent.tip_3_img}
              >
                <div style={{ minHeight: "11rem" }}></div>
              </BackgroundImage>

              <Card.Body>
                <Card.Title className="text-uppercase">
                  {residentialContent.tip_3_title}
                </Card.Title>
                <Card.Text>{residentialContent.tip_3_description}</Card.Text>
              </Card.Body>
            </Card>
          </CardDeck>
        </div>
      </Container>
    </Layout>
  )
}

export default Residential
